<template>
  <div>
    <div v-html="content" class="content_pad"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request(
        `/api/front/userAppAgreement/loadUserAppAgreement/${this.$route.query.id}`,
        {}
      )
        .then(res => {
          if (res.code === 1) {
            this.content = res.data.content;
            document.title = res.data.title;
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less">
.content_pad {
  margin: 20px;
}
</style>
